module.exports = {
	"email": "Email",
	"phone": "Phone",
	"name": "Name",
	"avatar": "Profile picture",
	"save": "Save",
	"saved": "Saved",
	"saving": "Storing",
	"edit": "Edit",
	"create": "Create",
	"remove": "Delete",
	"reinvite": "Re-invite",
	"createUser": "Create user",
	"loading": "Loading {resource}",
	"dashboard": "Dashbord",
	"project": "Project | Projects",
	"theProject": "The project",
	"map": "Map",
	"yourProject": "Your project | Your projects",
	"allProjects": "All projects",
	"noProjects": "No projects",
	"residential": "Home | Homes",
	"noResidentials": "No homes",
	"residentialTemplate": "Home type | Home types",
	"createResidentialTemplate": "Create home type",
	"createUnitTemplate": "Create unit type",
	"user": "User | Users",
	"settings": "Settings",
	"login": "Login",
	"logout": "Logout",
	"termsCondition": "Contract terms and conditions",
	"privacyPolicy": "Privacy",
	"cookiePolicy": "Cookie Policy",
	"menu": "Menu",
	"followUs": "Follow us",
	"getStarted": "Get started",
	"noAccess": "No access",
	"projectName": "Project name",
	"publish": "Publish",
	"publishing": "Publishing",
	"published": "Published",
	"unpublished": "Not published",
	"domain": "Domain",
	"retry": "Try again",
	"close": "Close",
	"showDetails": "Show details",
	"hideDetails": "Hide details",
	"createLead": "New interested party",
	"newLead": "New interested party",
	"lead": "Interest party | Interested parties",
	"leadMessage": "Notification",
	"message": "Message | Messages",
	"newMessage": "New message",
	"comment": "@:message",
	"noMessages": "No messages",
	"description": "Description",
	"showMore": "Show more",
	"noLeads": "No interested parties",
	"openhouse": "Open house | Open houses",
	"openhouseOnRequest": "Open house on request",
	"noOpenhouse": "No open houses",
	"createOpenhouse": "New open house",
	"from": "From",
	"to": "To",
	"date": "Date",
	"role": "Role | Roles",
	"flatfinder": "Home finder | Home finders",
	"unitSelector": "Unit selector | Unit selectors",
	"flatfinderTypeFlat": "Flat finder | Flat finders",
	"flatfinderTypePlot": "Plot finder | Plot finders",
	"flatfinderTypeOffice": "Office | Offices",
	"createFlatfinder": "New home finder",
	"createUnitSelector": "New unit selector",
	"home": "Home",
	"statistics": "Statistics",
	"notset": "Not determined",
	"projectmanager": "Project associate | Project associates",
	"customermanager": "Customer service representative | Customer service representatives",
	"complaintmanager": "Complaints manager | Complaints managers",
	"search": "Search",
	"searchFor": "Search for",
	"cancel": "Cancel",
	"confirm": "Confirm",
	"couldnotfindonquery": "No hits for \"{query}\"",
	"selectx": "Select {x}",
	"brochure": "Prospect",
	"downloadBrochure": "Download prospect",
	"logo": "Logo",
	"logoInverted": "Logo (bright version)",
	"favicon": "Favicon (Browser icon)",
	"gallery": "Gallery",
	"internalId": "Internal ID",
	"createProject": "New Project",
	"address": "Address",
	"postcode": "Postal code",
	"city": "Place",
	"county": "County",
	"latitude": "Longitude",
	"longitude": "Latitude",
	"position": "Position",
	"createResidential": "New home",
	"cadastre": "Cadastre",
	"residentialnumber": "Property unit number",
	"number": "Number",
	"street": "Street address",
	"floorplan": "Floor plan | Floor plans",
	"dragImageOrDrop": "Drag the image here or <b>click</b> to upload | Drag images here or <b>click</b> to upload",
	"dragFilesOrDrop": "Drag files {extension} here or <b>click</b> to upload",
	"dragFileOrDrop": "Drag file {extension} here or <b>click</b> to upload",
	"price": "Price",
	"residentialProperties": "Key info",
	"propertyType": "Home type",
	"propertyTypeWarning": "<b>NOTE!</b> You have selected home type \"{value}\" and must therefore enter values per unit.",
	"numberOfBedrooms": "Bedrooms",
	"numberOfRooms": "Rooms",
	"numberOfBathrooms": "Bathrooms/WC",
	"primaryRoomArea": "Primary rooms",
	"grossArea": "Gross building area",
	"useableArea": "Usable floor space",
	"floor": "Storey",
	"numberOfFloors": "Number of storeys",
	"plotArea": "Building plot area",
	"ownershipType": "Ownership",
	"numberOfParkingSpots": "Number of parking spots",
	"energyLabel": "Energy labelling",
	"energyLabelColor": "",
	"plotOwned": "Freehold property",
	"plotGroundTax": "Property tax",
	"plotOwnershipType": "Ownership (plot)",
	"communityTax": "Municipal taxes",
	"taxValue": "Tax value",
	"constructionYear": "Construction year",
	"renovatedYear": "Year renovated",
	"value": "Value",
	"add": "Add",
	"custom": "Self-defined",
	"yes": "Yes",
	"no": "No",
	"quantity": "Quantity",
	"none": "None",
	"bid": "Purchase",
	"addBid": "New bid",
	"bidUrl": "Link to bidding",
	"purchaseUrl": "Link for signing of purchase contract",
	"size": "Size",
	"sale": "Sale",
	"basis": "Basis",
	"presentation": "Presentation",
	"integration": "Integration | Integrations",
	"next": "Next",
	"prev": "Previous",
	"cover": "Cover",
	"propertyType:detached": "Detached house | Detached houses",
	"propertyType:flat": "Flat | Flats",
	"propertyType:terraced": "Townhouse",
	"propertyType:semidetached": "Duplex",
	"propertyType:plot": "Residential plot | Residential plots",
	"propertyType:leisure": "Cottage | Cottages",
	"propertyType:leisurePlot": "Recreational plot | Recreational plots",
	"propertyType:office": "Office | Offices",
	"openhouseReferences": "Where are open houses taking place",
	"isInterestedIn": "Interested in",
	"leadReferences": "@:isInterestedIn",
	"theWholeProject": "The entire project",
	"getInTouch": "Contact us",
	"leadFormTitle": "Notify your interest",
	"leadConfirm": "Thank you for your interest",
	"residentialStatus": {
		"draft": "@:unpublished",
		"upcoming": "Coming soon",
		"sale": "For sale",
		"sold": "Sold",
		"reserved": "Reserved"
	},
	"residentialStatus:sale": {
		"draft": "@:unpublished",
		"upcoming": "Coming soon",
		"sale": "For sale",
		"sold": "Sold",
		"reserved": "Reserved",
		"development": "Under development"
	},
	"residentialStatus:rent": {
		"draft": "@:unpublished",
		"upcoming": "Coming soon",
		"reserved": "Reserved",
		"rent": "For rent",
		"rented": "Rented",
		"development": "Under development"
	},
	"viewResidentials": "View homes",
	"status": "Status",
	"bidAction": "Purchase",
	"residentialSaleFee": "Expenses",
	"residentialSaleFixed": "Fixed price",
	"residentialSaleFeeIncluded": "Specified price includes expenses",
	"contact": "Contact | Contacts",
	"editContact": "Change contact",
	"createContact": "Create contact",
	"contactUpsell": "I wish to receive information about similar projects",
	"leadPrivacy": "I wish to be contacted with the consent of {0}",
	"contractPrivacy": "I am aware that {0}",
	"salesmanVisibility": "The contact information for customer service representatives on this project will be displayed on the project website",
	"areYouSure": "Are you sure?",
	"somethingWentWrong": "Something went wrong",
	"completed": "Completed",
	"complete": "Complete",
	"completing": "Completing",
	"youAreAboutToRemove": "You are deleting",
	"removeRelationsTitle": "You are also deleting the following:",
	"showAll": "Show all",
	"attachment": "Attachment | Attachments",
	"preview": "Preview",
	"transferToUser": "Move associated data to:",
	"upcomingDeadlines": "Upcoming deadlines",
	"noDeadlines": "No upcoming deadlines",
	"recentActivity": "Recent events",
	"noActivity": "No events",
	"task": "Task | Tasks",
	"noTasks": "No tasks",
	"new": "New",
	"showCoordinates": "Show coordinates",
	"readOurTerms": "Read the contract terms and conditions",
	"iAcceptTerms": "I have read and accept the contract terms and conditions",
	"sendAndAccept": "Proceed",
	"privacy": "Privacy Policy",
	"notfoundTitle": "The page does not exist",
	"projectWillPublish": "Published soon",
	"projectWillPublishAt": "Published on {date}",
	"projectUnpublished": "Project has ended",
	"pageMadeBy": "The page is created in",
	"processedBy": "Processed by",
	"markAsProcessed": "Mark as processed",
	"openhouseAtValue": "Open house for {value}",
	"noMoreResidentials": "No additional homes",
	"welcomeName": "Welcome {name}",
	"youHaveBeenAddedToCompany": "You have been added as a user in {company}.",
	"activateAccount": "Activate account",
	"pressButtonToActivateAccount": "Click the button to select your password and active the account.",
	"orgNo": "Org. no.",
	"howToGetLatLng": "To have the project placed on the map, we recommend that you use {0} to retrieve the correct coordinates. Address lookup often does not contain new addresses and you will therefore have to retrieve these manually. The position will also be more accurate.",
	"image": "Image | Images",
	"flatfinderBuilder": "Setup",
	"leaflet-v1": {
		"drawToolbar": {
			"actions": {
				"title": "Cancel drawing",
				"text": "Cancel"
			},
			"finish": {
				"title": "Complete drawing",
				"text": "Complete"
			},
			"undo": {
				"title": "Delete the last drawn point",
				"text": "Delete the last point"
			},
			"buttons": {
				"polygon": "Draw a selection"
			}
		},
		"drawHandlers": {
			"polygon": {
				"error": "Error!",
				"tooltip": {
					"start": "Click to draw a selection.",
					"cont": "Click to continue drawing a selection.",
					"end": "Click the first point to close this selection."
				}
			}
		},
		"editToolbar": {
			"actions": {
				"save": {
					"title": "Save changes.",
					"text": "Save"
				},
				"cancel": {
					"title": "Cancel editing, discard all changes.",
					"text": "Cancel"
				},
				"clearAll": {
					"title": "Delete all selections.",
					"text": "Delete all"
				}
			},
			"buttons": {
				"edit": "Edit selections.",
				"editDisabled": "No selections to edit.",
				"remove": "Delete selections.",
				"removeDisabled": "No selections to delete."
			}
		},
		"editHandlers": {
			"edit": {
				"tooltip": {
					"text": "Drag the points or select to edit.",
					"subtext": "Click cancel to discard changes."
				}
			},
			"remove": {
				"tooltip": {
					"text": "Click a selection to delete."
				}
			}
		}
	},
	"theme": "Theme",
	"selectTheme": "Select theme",
	"show": "Display",
	"activateMarketingButton": "Purchase additional visibility",
	"couldNotLoadContent": "Unable to load content",
	"couldNotLoadContentMessage": "Try to refresh the page. If the problem persists, please contact {0}.",
	"personalInformation": "Personal data",
	"bidInformation": "Bid information",
	"bidAuthentication": "Identification",
	"bidSigning": "Signing",
	"bidConfirmation": "Bid signed",
	"purchaseSigning": "Signing",
	"purchaseConfirmation": "Purchase contract signed",
	"socialSecurityNumber": "Social Security number",
	"bidApplicant": "Buyer",
	"bidHasCoApplicant": "Add co-applicant",
	"purchaseHasCoApplicant": "@:bidHasCoApplicant",
	"bidCoApplicant": "Co-applicant",
	"amount": "Amount",
	"bidExpiresAt": "Acceptance deadline",
	"bidTakeoverAt": "Desired takeover",
	"bidCondition": "Possible reservations",
	"passwordEnforcementMessage": "The password must consist of at least 8 characters, upper/lower case letters and at least 1 number",
	"bank": "Bank",
	"financeContact": "Contact person",
	"financingPlan": "Payment plan",
	"signingAuthority": "Authorised signatory",
	"bidContract": "Purchase confirmation",
	"purchaseContract": "Purchase contract",
	"shopContract": "Addon contract",
	"shop-partialContract": "Addon contract - Partial signing",
	"bidSignedMessage": "Your bid has been signed and sent. You will shortly receive a confirmation by email. As soon as your bid has been processed, you will receive a reply by email.",
	"purchaseSignedMessage": "Your purchase contract has been signed and sent. You will shortly receive a confirmation by email. As soon as your purchase contract has been processed, you will receive a reply by email.",
	"gotoProject": "Go to the project",
	"parent": "Parent",
	"goBack": "Back",
	"removeDemoContent": "Delete demo content",
	"unsavedChangesDialog": "You have unsaved changes that will be lost if you continue. Are you sure you want to continue?",
	"confirmation": "Confirmation",
	"yourDocumentsAreAttached": "Please find attached all documents",
	"accept": "Approve",
	"decline": "Reject",
	"document": "Document | Documents",
	"noDocuments": "No documents",
	"projectsite": "Project website",
	"signer": "Undersigned | Undersigned",
	"youDoNotHaveSignAuthority": "Only the authorised signatory can approve/reject this document",
	"update": "Update",
	"fieldsToSync": "Data to be overwritten",
	"selectAll": "Select all",
	"residentialTemplateSyncReferences": "Update associated homes",
	"askResidentialTemplateSyncReferences": "Do you wish to update associated homes?",
	"projectDomainSetup": "For the change to become visible online, you have to set up a DNS pointer for your domaine (see below). Remember that it may take a bit of time before the change becomes visible online. Contact us by chat if you have questions.",
	"lookingForAUserAdd": "Can’t find the user you are looking for?",
	"select": "Select",
	"syncReferencesWarning": "Be careful when selecting what data is to be overridden. All selected fields will be overridden with new content.",
	"themeDoesNotSupportColor": "The selected theme does not support the option for theme colour.",
	"themeColor": "Theme colour",
	"youAreHere": "You are here",
	"resetSelection": "Reset selections",
	"selection": "Selections",
	"thisIsADemo": "This is a demo",
	"userIsGuest": "Guest / Does not have access to the system",
	"guest": "Guest",
	"inviteSent": "Invitation sent",
	"demo": "Demo",
	"thisMessageHasBeenSentTo": "This message has been sent to",
	"tourSeoAnalyticsTagManager": "SEO / Analytics / Tag Manager",
	"tourDeveloperTools": "Use of development tools",
	"tourBusinessSite": "How to change company page",
	"tourRecommendedImageFormat": "Recommended image formats",
	"tourHowToAddUser": "How to add a user",
	"tourHowToCreateProjects": "How to create projects",
	"tourSingleResidentialSale": "Simple home sale",
	"tourHowToAddLeads": "How to add interested parties",
	"tourHowToCreateResidentials": "How to create homes",
	"addonPresentation": "Presentation",
	"addonBid": "Bidding",
	"addonPurchase": "Purchase contract",
	"addonShop": "@:shop",
	"addonShopPurchase": "Addon shop - Signing",
	"tourAboutProjectDashboard": "About the project dashboard",
	"tourHowToEditResidentials": "How to edit homes",
	"tourHowToDeleteResidentials": "How to delete homes",
	"tourTitleOnScreen": "Help guides (Interactive)",
	"tourTitleCourseVideos": "Instructional videos",
	"tourTitleManuals": "Help guides (Manual)",
	"flatfinderIsEntry": "Main home finder (show this first on the project page)",
	"flatfinderCreateResidentialWarning": "When you setup a home finder you need to connect to homes. Therefore, it is important that you first create the homes before setting up the home finder.",
	"flatfinderCreateResidentialWarningTitle": "We see that you have not created any homes",
	"flatfinderCreateResidentialWarningException": "If you are not going to setup homes at this time, you can disregard this message and continue.",
	"continue": "Continue",
	"standard": "Standard",
	"richTextUpdateLink": "Update link",
	"richTextAddLink": "Add link",
	"multiplicatorMonthly": "Months",
	"multiplicatorProject": "Project",
	"contractStatusAccepted": "Approved",
	"contractStatusDeclined": "Rejected",
	"contractStatusDraft": "Draft",
	"contractStatusSigned": "A document is awaiting approval",
	"contractStatusUnsigned": "You have a document for signing",
	"bidErrorStatus": "The home is not for sale",
	"bidErrorAddon": "Module for bidding is not activated for this project",
	"purchaseErrorStatus": "The home is not sold",
	"purchaseErrorAddon": "Module for purchase contract is not activated for this project",
	"signed": "Signed",
	"gotoSigning": "Go to signing",
	"sign": "Sign",
	"username": "Username",
	"accountUrl": "Link to account",
	"password": "Password",
	"otherBidAcceptedMessage": "Another bid was accepted",
	"bidConfirmationMessage": "We have received your bid and you will receive a reply from us as soon as possible",
	"bidAcceptMessage": "Your bid has been accepted",
	"bidDeclinedMessage": "Your bid has been rejected",
	"purchaseConfirmationMessage": "We have received your purchase contract and you will receive a reply from us as soon as possible",
	"purchaseAcceptMessage": "Your purchase contract has been accepted",
	"purchaseDeclinedMessage": "You purchase contract has been rejected",
	"shopConfirmationMessage": "We have received your signed addon order. You will receive a confirmation once the counterparty has signed it. Please find attached an overview of your order.",
	"shopAcceptMessage": "Your addon contract has been accepted",
	"shopDeclinedMessage": "Your addon contract has been rejected",
	"contractExpiredMessage": "Deadline has expired",
	"reason": "Reason",
	"pressHereToCancelBid": "Do you want to cancel the purchase?",
	"youAreUsingBidAddon": "You are using Kvass - Purchase module",
	"pressHere": "Click here",
	"void": "Cancel",
	"reasonWillBeSentToApplicants": "This message will be forwarded to the customer",
	"howToBuildContract": "For the document to be correctly filled out, you need to set up a fillable PDF. Click the help button below to see how you can do this.",
	"contractType": "Type",
	"createDocument": "Create document",
	"upload": "Upload",
	"bidUploadInfo": "After you have uploaded the signed purchase confirmation, the home will be marked as sold",
	"onSoldStatusDialog": "To mark this home as sold, you must upload the signed purchase confirmation.",
	"excludingVat": "excl. VAT.",
	"includingVat": "incl. VAT.",
	"priceOnRequest": "Price available upon request",
	"priceFrom": "Price from",
	"pricePerMonth": "Price per month",
	"salePrice": "Sales price",
	"priceMargin": "Markup",
	"purchasePrice": "Cost price",
	"readMore": "Read more",
	"upsaleRequestTemplate": "I want help with \"{0}\"",
	"publishedFrom": "Published from",
	"publishedTo": "Published to",
	"customerService": "Customer service",
	"residentialTemplateExplanation": "By creating home types you avoid having to update the same basis several times. You can quickly and easily update all homes/flats that are based on this home type, while at the same time you retain the option of changing the homes/flats individually at the project level.",
	"unitTemplateExplanation": "By creating unit types, you don’t need to maintain the same foundation several times. You can quickly and easily update all units that are based on this type, while at the same time retaining the option of editing the units individually at the project level.",
	"product": "Product | Products",
	"createProduct": "Create product",
	"allProducts": "All products",
	"category": "Category | Categories",
	"productCategorySample": "e.g. Floor, Wood floor, Heating, Cooling & Plumbing",
	"more": "More",
	"residentialStatusBidLock": "You have activated “Purchase module” and cannot change the status to or from “sold” manually. Access home to change status",
	"noAnalyticsCollected": "We do not yet have enough total data. You will see statistics here as soon as people visit the website.",
	"sendEmail": "Send email",
	"customThemeActiveMessage": "You are using a customised template for this project.",
	"validDnsSetupMessage": "The domain is not correctly set up - check the instructions below and try again.",
	"siteSettings": "Page settings",
	"showOpenhouseOnRequestIfNone": "Show \"@:openhouseOnRequest\" if no open houses are planned",
	"showLeadUpsell": "Show \"@:contactUpsell\" on the interested party form",
	"showShopOnResidentialPage": "Show addon shop on the home site",
	"showShop": "Show addon shop",
	"typeHere": "Write here",
	"shopCategoryExamples": "e.g., Kitchen, Bathroom",
	"shopSubcategoryExamples": "e.g. Tiles, Interior design",
	"shopAddProduct": "Select product",
	"shopAddCategory": "@:addCategory",
	"shopAddSubcategory": "Add sub-category",
	"lookingForAProductAdd": "Can’t find the product you are looking for?",
	"hideSold": "Hide sold",
	"assetManagerTitleBrochureDirect": "Download prospect",
	"assetManagerTitleBrochureLead": "Download prospect",
	"assetManagerTitleBrochureRequest": "Receive copy of prospect",
	"assetManagerActionBrochureLead": "Download prospect",
	"assetManagerActionBrochureRequest": "Send",
	"assetManagerFeedbackBrochureRequest": "You will receive the prospect shortly.",
	"assetManagerCommentBrochureRequest": "I wish to receive a copy of the prospect.",
	"brochureDownloadStrategyLabel": "Select how the prospect can be downloaded",
	"brochureDownloadStrategyDirect": "Download directly",
	"brochureDownloadStrategyLead": "Notify interest first",
	"brochureDownloadStrategyRequest": "Send enquiry/Send manually",
	"selected": "Selected",
	"shopDefaultProduct": "Standard delivery",
	"shopProductUpgrade": "Upgrade options",
	"totalPrice": "Total price",
	"shopContactSellerForOtherInquiries": "If you have other requests, please contact the seller",
	"shopNotAvailableSize": "Open this page on a larger display to get the best experience of the addon shop",
	"gotoItem": "Go to {0}",
	"activate": "Activate",
	"whatDoYouWantToCopy": "What should be copied?",
	"helpTools": "Manuals/Guides",
	"needHelp": "Do you need assistance?",
	"title": "Title",
	"nearbyarea": "Area",
	"shopSync": "Copy",
	"shopSyncAll": "The entire addon shop",
	"badge-bid": "@:bid",
	"badge-comment": "@:comment",
	"badge-openhouse": "@:openhouse",
	"badge-shopcontract": "@:addonShop",
	"badge-lead": "@:lead",
	"badge-purchase": "@:addonPurchase",
	"badge-task": "Task",
	"badge-customcontract": "@:contract",
	"prepareContract": "Preparation of contract",
	"prepareShop": "Preparation of addons",
	"preparePurchaseContract": "Preparation of purchase contract",
	"buyer": "Buyer | Buyers",
	"sendToSigning": "Send for signing",
	"deadline": "Deadline",
	"shopLink": "Link to addon shop (presentation)",
	"shopLinkWithSelection": "Link to addon shop (options & signing)",
	"shopSigning": "Signing",
	"shopConfirmation": "Confirmation",
	"shopIntro": "Introduction",
	"shopSummary": "Summary",
	"shopSignedMessage": "Your addon contract has been signed and sent. You will shortly receive a confirmation by email. As soon as your addon contract has been processed, you will receive a reply by email.",
	"shopSigningNotReady": "Signing is not yet available and you will be notified as soon as you can sign",
	"startShopContract": "Start addon process",
	"shopReadyTitle": "Your addon shop is ready",
	"shopReadyMessage": "You can now view and select what addons you want for your home. \nSigning is not yet available but you can already now make your first selections. You will be notified as soon as signing is available.",
	"shopWelcomeMessage": "Welcome to your addon shop - here you can view and select what addons you want for your home.\n\nAs soon as you have made your selections, you can proceed to the summary where you will have the option of signing your order.",
	"shopUnsignedMessage": "You can now select and sign your addons",
	"gotoShop": "Continue to the addons shop",
	"shopContractStatusReady": "Ready (sent to buyer)",
	"shopContractStatusDraft": "Draft",
	"shopContractStatusUnsigned": "Awaiting signature",
	"shopContractStatusSigned": "@:signed",
	"shopContractStatusExpired": "The deadline has expired",
	"shopContractNotSold": "The home is not yet for sale. As soon as the status of the home is changed to sold, you will be able to commence the signing process.",
	"shopContractAttachment": "Attachments (to be signed by buyer)",
	"shopContractStartRequirements": "Before you can start the addon process, you must enter buyer. \nRemember to save your changes. ",
	"shopContractRememberSignableFrom": "Remember to include \"@:signableFrom\" so that the buyer can sign the addon order.",
	"file": "File | Files",
	"import": "Import",
	"productImport": "Product import",
	"importedXItem": "Imported {count} {item}",
	"importFailedResolveIssues": "The import could not be implemented. Below, you will find an overview of what has gone wrong. Please correct and try again.",
	"pressButtonBelowToGetStarted": "Click the button below to get started",
	"signingDeadlineIs": "The deadline for signing is",
	"shopLinkPresentation": "Presentation",
	"shopLinkSigning": "Options / Signing (for buyer)",
	"export": "Export",
	"page": "Page | Pages",
	"tourGetStartedSingleShop": "How to get started with: Individual module - Addons",
	"tourHowToAddPartDeadlinesShop": "How to set sub-deadlines for addon signing",
	"tourHowIfOrIfNotShopWork": "How the addon process works",
	"tourGetStartedShop": "How to get started with: Addon shop",
	"tourGetStartedShopContract": "How to get started with: Digital addon signing",
	"tourTaskManagement": "How task management works in the customer follow-up system",
	"tourGetStartedPurchaseContract": "How to get started with: Digital purchase contract",
	"tourGetStartedBidContract": "How to get started with: Digital purchase module",
	"tourGetStartedSingleBidContract": "How to get started with: Individual module - Digital purchase module",
	"tourHowToEditProjectResidentials": "How to edit the project/homes",
	"tourHowToCreateFlatfinder": "How to create/edit home finders",
	"tourHowToCreateResidentialTemplate": "How to create/edit home types",
	"tourHowToBuildContract": "How to set up purchase confirmation",
	"tourHowDashboardWorks": "How the dashboard works",
	"tourHowToCreateProjectTeaser": "How to create a teaser (Coming soon)",
	"tourRoles": "How to administer users",
	"tourHowToPublish": "How to publish the project under a domain",
	"tourTitleAdditionalServices": "Would you like assistance?",
	"tourHowToBuildPDF": "How to set up a fillable PDF",
	"tourHowToHandleLead": "How to manage interested parties",
	"tourHowToHandleOpenhouse": "How to manage open houses",
	"howManyUnitsOnProject": "How many units in total are being sold?",
	"attachmentIsEncryptedWithSSN": "Note that the attachment is encrypted - the password is your national ID number. The document you have received is a copy and the original is with the seller.",
	"contractApprovalWarning": "Remember to check the content of the document before approval.",
	"addon:project": "Project",
	"addon:project:presentation": "Project / Home site",
	"addon:project:bid": "Purchase module",
	"addon:project:purchase": "Purchase contract",
	"addon:project:shop": "Addons - Presentation",
	"addon:project:shop-purchase": "Addons - Sale",
	"addon:custom-contract": "Document signing",
	"addon:api": "API access",
	"addon:flatfinder": "Home finder",
	"addon": "Module | Modules",
	"fixedPrice": "Fixed price",
	"areaPrice": "Price per m²",
	"customerPrice": "Price for customer",
	"establishment": "Establishment",
	"ownershipType:leasehold": "Cooperative",
	"ownershipType:freehold": "Owner (Condominium)",
	"ownershipType:other": "Other",
	"ownershipType:partOwnership": "Share",
	"ownershipType:collectiveOwnership": "Stock",
	"plotOwnershipType:leasehold": "Cooperative",
	"plotOwnershipType:freehold": "Owner (Condominium)",
	"plotOwnershipType:other": "Other",
	"plotOwnershipType:partOwnership": "Share",
	"plotOwnershipType:collectiveOwnership": "Stock",
	"subpage": "Subpage | Subpages",
	"orderItem": "Order {item}",
	"ShopPurchaseRequiresShop": "To be able to use “Addon - Sale”, you also need “Addon - Presentation”",
	"projectUnitLimitReached": "You can create {0} units for this project. To add additional units, you need to upgrade to an expanded licence. Please contact support@kvass.no or chat with us to upgrade.",
	"slug": "Slug",
	"content": "Content",
	"setArea": "Set area",
	"rounding:none": "None",
	"priceRounding": "Price rounding",
	"exampleShort": "e.g.,",
	"productOverrideMessage": "{value} is linked to a product catalogue. Click “edit” to set a separate value.",
	"productOverrideReset": "Link \"{value}\" to a product catalogue - remove separate value.",
	"priceMissingArea": "Area missing",
	"total": "Total",
	"instagramProfile": "Instagram profile",
	"numberOfPosts": "Number of posts",
	"followUsOn": "Follow us at {value}",
	"customFieldsResidentialTemplatesLabel": "Presentation of home types",
	"signableFrom": "Can be signed from",
	"setPartialDeadlines": "Set sub-deadlines",
	"removePartialDeadlines": "Remove sub-deadlines",
	"partialDeadline": "Sub-deadline | Sub-deadlines",
	"madeBy": "A service from",
	"gotoSummary": "Go to summary",
	"selectCategoriesToSign": "Select categories to be signed",
	"shopBuyerStatusDraft": "No yet available for signing",
	"shopBuyerStatusUnsigned": "Ready for signing",
	"wholeShop": "The entire addon shop",
	"company": "Company",
	"companyName": "Company name",
	"businessSite": "Company page",
	"private": "Private",
	"footer": "Footer",
	"creatingContracts": "Creating documents for signing, please wait a moment",
	"pressButtonBelowToGoToShop": "Click the button below to proceed to your addon shop",
	"youHaveNewShopInfo": "You have received updated information in your addon shop",
	"youHaveAnUpcomingDeadline": "You have an upcoming deadline",
	"hiName": "Dear {name}",
	"shopPresentationSidebarDescriptionTitle": "Set up you home",
	"shopPresentationSidebarDescriptionContent": "Here, you can view what upgrades are available for this home.",
	"signingLoadingMessage": "We are preparing your documents - please wait a moment (this will take approx. 15-30 sec.)",
	"tag": "Label | Labels",
	"noTags": "No labels",
	"youHaveANewMessageFrom": "You have received a new message from {from}",
	"nameSays": "{name} says",
	"gotoDialog": "Go to the conversation",
	"youCanAswerThisEmail": "You can reply to this email - your reply will be forwarded",
	"send": "Send",
	"note": "Note",
	"thisMessageWasSentWith": "This message has been sent with",
	"addCategory": "Add to category",
	"availableIf": "Available if",
	"availableIfNot": "Not available if",
	"xActiveConditions": "No active rules | {count} active rule | {count} active rules",
	"setConditions": "Set rules",
	"displayName": "Open house name",
	"useDisplayName": "Use a different open house name",
	"statisticsVisitors": "Visitor numbers",
	"statisticsSalesResidentials": "Sales numbers",
	"statisticsSalesShop": "Sales numbers (Addons)",
	"externalUserAlert": "You are attempting to add a user who is outside your organisation ({domain}). \nThe person in question will have access to the projects and accompanying data in the solution.\n\nPlease confirm that you wish to add {email}.",
	"sendMessageToSeller": "Send a message to the seller",
	"sendMessageToSellerButtonLabel": "Questions or concerns? – Contact us here",
	"sent": "Sent",
	"member": "Member | Members",
	"editTask": "Edit task",
	"createTask": "Create task",
	"undone": "Not commenced",
	"noDescription": "No description",
	"noDeadline": "No deadline",
	"youHaveANewTask": "You have received a new task",
	"gotoTask": "Go to the task",
	"gotoDashboard": "Go to the dashboard",
	"youHaveXTaskDueIn": "You have {count} task that is due by {dueby} | You have {count} tasks that are due by {dueby}",
	"projectAdditionalFieldsDescriptionFooter": "Add footer. e.g.: About us/Access",
	"projectAdditionalFieldsDescriptionResidentailTemplate": "Select which of your home types you wish to present on the project page.",
	"projectAdditionalFieldsDescriptionArea": "Enter text and images to present the area or similar.",
	"projectAdditionalFieldsDescriptionSubpage": "Create subpages where you can present images and text. You can link to subpages by adding links in the text fields.",
	"clone": "Dupliser",
	"type": "Type | Types",
	"filter": "Filter",
	"noShopDefaultProduct": "No standard delivery",
	"customer": "Customer | Customers",
	"purchaseManuallyConfirmed": "Uploaded purchase contract is signed",
	"cannotAddMoreUsers": "You cannot select additional users. Please contact support if you wish to add additional users.",
	"videoPreviewMessage": "Add to {type} link for preview of video",
	"videoLink": "{type} link",
	"profile": "Profile | Profiles",
	"redirectToExternalSite": "Redirect to external site",
	"residentialRedirectUrlExample": "e.g., https://...",
	"optional": "optional",
	"residentialRedirectUrlExplanation": "Use this field if you wish to direct visitors to another website",
	"createdAt": "Create it",
	"overview": "Overview",
	"mediaTypeComboMessage": "Or click on the button to select from other types of media",
	"mediaTypeDefaultMessage": "Select media type via the button",
	"userIsDeveloper": "Show development tools",
	"noFloorplansSetup": "No floor plans set up",
	"mark": "Select",
	"createResidentialTemplateBeforeShop": "To create the addon shop you must first create the home type",
	"noShopsetsSetup": "No product packages are set up",
	"shopSet": "Product package | Product packages",
	"productsThatArePartOfSet": "Products included in the product package",
	"manualSelectAndSkip": "Select on you own (Skip)",
	"event": "Incident | Incident",
	"url": "URL",
	"enabled": "Activated",
	"webhook": "Webhook | Webhooks",
	"createWebhook": "Create webhook",
	"setting": "Setting | Settings",
	"webhookSecret": "Secret",
	"attempt": "Attempt",
	"of": "of",
	"log": "Log | Log",
	"noWebhooks": "No webhooks",
	"webhookIsSystem": "System integration",
	"noLogs": "No logs",
	"token": "Token | Tokens",
	"createToken": "Create token",
	"noTokens": "No tokens",
	"acl": "Access control",
	"tokenSecretMessage": "Please copy this key and store it in a safe place. For safety reasons, we will only show it once.",
	"shopContractNoticeSellerApproachingSubject": "Buyer’s signing deadline is expiring",
	"shopContractNoticeSellerOverdueSubject": "Buyer’s signing deadline has expired",
	"shopContractNoticeSellerExpires": "Buyer has not completed signing of addon contract.",
	"unit": "Unit | Units",
	"noUnits": "No units",
	"noMoreUnits": "No more units",
	"createUnit": "Create unit",
	"unitTemplate": "Unit type | Unit types",
	"residentialSaleWithBroker": "Sell with broker ",
	"residentialSaleSelf": "Digital contract signing",
	"residentialSaleWithBrokerBidHelperText": "Here, you can add an optional link with which you can sell the unit. The link will be available \nunder the “purchase” button on the home site. ",
	"bidContractMissingShowBidUrl": "Purchase confirmation missing. Upload purchase confirmation for the project to view bidding link. ",
	"residentialSaleBidHelperText": "With this link you can sell the unit digitally. \nThe link is available under the “purchase” button on the home site, but the link can also be copied from here. \nPurchases that are completed via this link will appear on the seller’s dashboard. ",
	"residentialSaleBidActiveHelperText": "Purchase module is activated. If you wish to use a different sales method you can order integration with the system. \nContact us via the button below for more information and ordering. ",
	"pressButtonBelowToGoToShopNoticeSeller": "Click the button for more information about the addon shop",
	"copy": "Copy",
	"copied": "Copied",
	"paywallContentFieldUsedByFollowingModules ": "These fields are not relevant for activated modules",
	"paywallContentPressButtonToReadMore": "Click on the button to read more or to activate the module | Click on one of the buttons to read more or to activate the module",
	"deliveryLogClickLabel": "{resource} delivered - opened",
	"deliveryLogOpenLabel": "{resource} delivered - seen",
	"deliveryLogFailedLabel": "{resource} could not be delivered",
	"deliveryLogDeliveredLabel": "{resource} delivered - not opened",
	"deliveryLogUnknownLabel": "{resource}  - awaiting opening",
	"invite": "Invitation",
	"info": "Info",
	"oneOrMoreDeadlinesExpired": "One or more deadlines has/have expired",
	"projectShopsOpenByBuyer": "Opened by buyer",
	"contractStatusNotAccepted": "Awaiting approval",
	"noMoreShops": "No additional addon shops",
	"projectShopsCreateResidentialsWarning": "Create homes before you set up addon shops.",
	"projectShopsEditShop": "Not created",
	"projectShopsCreateResidentials": "Create homes",
	"projectShopsNotSold": "Not sold",
	"projectSaleBrokerGuide1": "Under “sale” on units, you can add links to the purchasing process from broker solutions",
	"projectSaleBrokerGuide2": "Such as from secure bidding.",
	"projectSaleBrokerGuide3": "If desired, integration with broker solutions can also be ordered.",
	"residentialsFlatfinderGuideInfo": "Once you have added units, you can add home finders if desired. To do this, go to the project dashboard and select “create”, then “home finder”.",
	"shop": "Addon shop | Addon shops",
	"devtools": "Development tools",
	"embed": "Embedding",
	"imageRecommendation": "Recommended image format: {width} {symbol} {height}",
	"height": "height",
	"width": "width",
	"imageFlatfinderRecommendation": "Free selection - the viewing will change to match",
	"consentRequiredTitle": "Essential cookies",
	"consentRequiredDescription": "Essential cookies maintain essential features such as page navigation and access to secure parts of the website. The website will not function optimally without these cookies.",
	"consentStatisticsTitle": "Statistics",
	"consentStatisticsDescription": "Analytics cookies help us improve our website by collecting and reporting statistics on use. The information is anonymised.",
	"consentMarketingTitle": "Marketing",
	"consentMarketingDescription": "Marketing cookies are used to show content from social media and advertisements that are more relevant and engaging.",
	"consentDialogTitle": "Would you like to accept cookies?",
	"consentDialogDescription": "We use cookies to give you the best possible user experience. The cookies are used to analyse our traffic, improve our website, and give content and advertisements a personal touch.\n  For more information, see our <a href=\"/api/legal/privacy/tenant\">Privacy Policy</a>.",
	"consentDialogLabelAccept": "Yes, accept all",
	"consentDialogLabelAcceptAll": "Accept all",
	"consentDialogLabelConfirm": "Confirm",
	"consentDialogLabelConfigure": "No, change settings",
	"editConsents": "Change privacy settings",
	"noAttachments": "No appendix",
	"tourVideoUpload": "How to add video",
	"language": "Language",
	"textOverwriteWarning": "The following text will be displayed if {value} is missing:",
	"cookieVideoBlockMessage": "We need your consent to play this video",
	"sizes": "Sizes",
	"productLink": "Product link",
	"showDefaultSelection": "Display standard option",
	"noUpgradesSelected": "No upgrades selected",
	"setValue": "Set {value} ...",
	"internalName": "Internal name",
	"useInternalName": "Use own internal name",
	"shopWelcomeText": "Welcome text",
	"scopedProduct": "Not from catalogue",
	"contactImport": "Contact import",
	"importTemplateDescription": "for importing",
	"socialMedia": "Social media",
	"downloadTemplate": "Download template",
	"projectCreationVideoMessage": "Check out how you can use Kvass for {type} - see the video above!",
	"singleResidential": "Single home | Single homes",
	"myPage": "My page",
	"contractStatusNotAcceptedBySeller": "Awaiting seller approval",
	"contractStatusAcceptedBySeller": "Approved by seller",
	"followSoMeTitle": "Follow us on social media",
	"projectAdditionalFieldsSoMeDescriptionArea": "Add a link to your social media and display it on the website.",
	"projectAdditionalFieldsSeoDescriptionArea": "Overwrite SEO customisations (title and description) Read more",
	"projectAdditionalFieldsFacebookAdsDescriptionArea": "Add fields for Facebook advertisement setup",
	"SEO": "SEO",
	"facebookAds": "Facebook advertisement",
	"back": "Back",
	"newFolder": "New folder",
	"dragFolderOrDrop": "Drag folders here or <b>click</b> to upload",
	"managementOperationMaintenance": "Management, Operation, Maintenance",
	"residentialAccount": "My page",
	"clickToFilter": "Click to filter",
	"imagesOnlyAsIllustration": "Images are included for illustrative purposes",
	"foundXResults": "Found {0} results",
	"active": "Active",
	"inactive": "Inactive",
	"contract": "Contract | Contracts",
	"buyConfirmation": "Buy signed",
	"residentialAccountLogin": "Log in to my page",
	"loginPressButton": "Click the button below to log in",
	"loginVisitLink": "Alternatively, copy this link into your browser",
	"loginLinkExpires": "This link will expire in {expires} minutes",
	"loginGetNewLink": "receive a new link",
	"residentialAccountYourUnits": "Your units",
	"residentialAccountLoginTooSeeUnits": "Log in to see your units",
	"loginEnterValue": "Enter {value} and click \"Complete\".",
	"loginSuccessMessage": "You should now have received an e-mail with more information",
	"sortOn": "Sort by",
	"highLow": "high-low",
	"lowHigh": "low-high",
	"syncUser": "Retrieve user data",
	"lowHighLetter": "Z - A",
	"highLowLetter": "A - Z",
	"addFilter": "Add filter",
	"resetFilter": "Reset filter",
	"validTo": "Valid until",
	"registrationComplete": "You have completed the registration process.",
	"clickToLogin": "Click the button below to log in.",
	"welcomeToKvass": "Welcome to Kvass",
	"enterPasswordAndClickComplete": "Enter your password and click \"Complete\".",
	"tokenExpiredRequestNew": "Your invitation has expired, please request a new one. ",
	"author": "Author",
	"post": "Post | Posts",
	"createPost": "Create post",
	"shopShort": "Addons",
	"developer": "Developer",
	"access": "Access | Accesses",
	"supplier": "Supplier | Suppliers",
	"technicalDescription": "Technical description",
	"addTechnicalDescription": "Add technical description",
	"switchTenant": "Change account",
	"leadMessageSent": "Thank you for your interest. You will soon be contacted by a customer service representative.",
	"externalUserAlertAdmin": "Access to projects and accompanying data in the solution. ",
	"externalUserAlertNoRights": "No access to data in the solution",
	"externalUserAlertRoles": "Role as: {roles} ",
	"externalUserAlertAccess": "Access to: {access} ",
	"shopPresentationPreviewAlert": "Addon shop is missing data. \nPreview will become available once the addon shop is set up.",
	"addon:project:complaint": "Complaints",
	"archived": "Archived",
	"showArchived": "Show archived",
	"archive": "Archive",
	"integrationCompliance": "It is hereby confirmed that Kvass is instructed to transfer personal data through our integration, and that I/we independently act as the data controller for the transfer, as well as the further processing of the personal data.",
	"documentSigned": "Document signed",
	"documentSignedConfirmationMessage": "The document has been signed and sent. You will shortly receive a confirmation by email. As soon as your document has been processed, you will receive a reply by email.",
	"integrationNotificationEmailBody": "We would like to inform you that a new {type} has been added to the account: {account}.",
	"integrationNotificationEmailBodyReminder": "Remember also to ensure that necessary data processing agreements with all subcontractors are in place.",
	"integrationNotificationEmailSubject": "A new {type} with the name \"{name}\" has been added to",
	"noContracts": "No contracts",
	"awaitingSignature": "Awaiting signature",
	"residentialAddressControl": "Check address",
	"residentialAccountNotSold": "This unit has not been sold yet. Settings will be available as soon as the status of the unit is changed to sold.",
	"createContract": "Create contract",
	"lastUpdated": "Last updated",
	"complaint": "Complaint | Complaints",
	"lookingForAContactAdd": "Can’t find the contact you are looking for?",
	"orderConfirmationCondition": "All activated products have a lock-in period of {lockInPeriod}. Products are automatically renewed on a continuous basis after {noticePeriod} unless they are cancelled by one of the parties in writing before the current period ends.",
	"orderConfirmationConditionShort": "{lockInPeriod} lock-in period / automatic renewal after {noticePeriod} ",
	"orderConfirmation": "Order confirmation",
	"unitLimitMessage": "You can create up to {limit} units.",
	"taskStatusUndone": "Open",
	"taskStatusDone": "Completed",
	"taskStatusRejected": "Rejected",
	"taskStatusRequest": "Request",
	"subjectArea": "Area of expertise",
	"showCompleted": "Show completed",
	"complaintRequest": "Enquiry | Enquiries",
	"complaintTermsOfRequest": "What is it about?",
	"complaintAddSelection": "Add selection",
	"complaintRegister": "Submit complaint",
	"companyOrder": "{company} has ordered the service",
	"activatedBy": "The service was activated by {user}",
	"orderConfirmationAttached": "Attached is an order confirmation.",
	"install": "Install",
	"uninstall": "Uninstall",
	"installed": "Installed",
	"residentialAccountPublishAlert": "Once published, owners will be notified of log-ins to My page.",
	"reset": "Reset",
	"showLess": "Show less",
	"residentialAccountAccess": "You have been given access to My page",
	"taskChangeStatus": "{type} '{title}' is {status}",
	"taskChangeStatusSubject": "{type} is {status}",
	"residentialAccountSettingsShowShop": "Makes the addon shop on My page available",
	"residentialAccountSettingsShowShopSublabel": "Show summary, deadlines, and other relevant content",
	"residentialAccountSettingsShowShopDocuments": "Show documentation of ordered addons on My page",
	"residentialAccountSettingsShowShopDocumentsSublabel": "Documentation is automatically generated and will be shown under MOM contents",
	"residentialAccountSettingsShowResidentialDocuments": "Show unit documents on My page",
	"residentialAccountSettingsShowResidentialDocumentsSublabel": "Contracts, floor plans, appendices, and prospects",
	"redirectUrl": "Forwarding address",
	"contactSeller": "Contact seller",
	"importFromCatalog": "Retrieve from catalogue",
	"reject": "Reject",
	"rejected": "Rejected",
	"addon:project:flatfinder": "Home finder",
	"blockComplaints": "Block complaints for this unit",
	"unblockComplaints": "Undo block of complaints for this unit",
	"complaintsBlocked": "Complaints for this unit have been blocked.",
	"taskStatusChangeToUndone": "processing",
	"taskStatusChangeToDone": "processed",
	"taskStatusChangeToRejected": "rejected",
	"attachmentsSigningUploadWarning": "Appendices must be reviewed before signing can be completed. \nNOTE: Appendices will not be included with the main contract to be signed.",
	"addonIsIncludedIn": "{addon} is included in {in}",
	"tourHowToEmbedFlatfinder": "Embed home finder on external pages",
	"helpers:roleManager": "Can edit projects and units.",
	"helpers:roleSigningAuthority": "Can approve or reject purchases.",
	"helpers:roleSalesman": "Granted read-only access and displayed as a contact person.",
	"helpers:roleShopManager": "Can edit addon shops.",
	"helpers:roleShopSigningAuthority": "Can approve or reject addon contracts.",
	"helpers:roleShopSalesman": "Granted read-only access and displayed as a contact person.",
	"salesmanShopVisibility": "The contact information for customer service representatives for addons will be displayed in the signing process.",
	"productBundleContains": "Includes the following products",
	"productBundle": "Product pack",
	"rent": "Rental",
	"saleType": "Sale type",
	"actionLabel:export": "Export",
	"actionLabel:integration": "Integrations",
	"action": "Action | Actions",
	"helpers:productBundle": "A product pack is composed of multiple individual products. You can choose which products to include, and the end customer can select the product pack and view information about each individual product.",
	"office": "Office",
	"integrationDpaRecommendation": "Suggestion for data processing agreement",
	"ordinaryProductPrice": "Ordinary product price",
	"helpers:shopPricingMethodTitle:add": "Standard",
	"helpers:shopPricingMethodDescription:add": "Here, the standard product is always included in the price. The price of upgrades is based on the ordinary product price.",
	"helpers:shopPricingMethodTitle:diff": "Difference",
	"helpers:shopPricingMethodDescription:diff": "Here, the difference between the standard product and the upgrade is estimated.",
	"selectMethod": "Select method",
	"priceCalculation": "Price estimation",
	"unitNumber": "Unit number",
	"productUpdateAffectedProducts": "Changes affect {products} product selections in {shops} addon shops",
	"upcomingLabel": "Coming soon",
	"hide": "Hide",
	"residentialAdditionalFieldsLabel": "Other fields to add:",
	"link": "Link | Links",
	"loggingIn": "Log in",
	"setDueDates": "Set deadlines",
	"complaintDescription": "Describe the complaint",
	"helpers:managementOperationMaintenance": "Upload MOM documents here: All documents will be shown on My page. \nFurthermore, you have the option to show documentation for addons, floor plans, contracts, and appendices related to the unit. (can be activated in settings)",
	"helpers:roleComplaintManager": "Receiving all incoming complaint cases",
	"residentialAccountLoginCondition": "E-mail address must be registered in the system",
	"noComplaints": "No complaints",
	"complaintDisclaimerConsent": "I confirm that the investigation of unfounded complaint enquiries may be charged to the customer with our actual costs.",
	"complaintDisclaimerEdit": "Edit disclaimer",
	"fdvControlLabel": "MOM is uploaded and checked",
	"fdvControlSublabel": "Tick the box as soon as the MOM is complete and checked.",
	"shopEditDueAtUnitNotSold": "The home has not been sold yet. As soon as the status of the home is changed to sold, you will be able to set deadlines.",
	"confirmed": "Confirmed",
	"control": "Check",
	"noMembers": "No members",
	"complaintRoleMissing": "The role for handling complaints is missing from the project. ",
	"folder": "Folder | Folders",
	"helpers:complaintsList": "The buyer can submit complaints via My page, and all complaint cases for this unit will be displayed here.",
	"completeTask": "Complete task",
	"changeStatus": "Change status",
	"confirmRequest": "Confirm request",
	"rejectRequest": "Reject request",
	"setTaskToUndone": "Change back to “Open”",
	"cannotEditMultipleResidentialFieldsSameSaleType": "The units have different sale types and cannot be edited together",
	"noContacts": "No contacts",
	"filterEmptyState": "Change your filters and try again",
	"invoice": "Invoice",
	"receiver": "Recipient | Recipients",
	"addon:project:presentation:sublabel": "Quickly set up a website with a home finder",
	"addon:project:bid:sublabel": "Send and sign the purchasing contract digitally.",
	"addon:project:purchase:sublabel": "Easily & digitally sell via the project/home site.",
	"addon:project:shop:sublabel": "Present addons digitally via the project/home site or through a separate link.",
	"addon:project:shop-purchase:sublabel": "Sell addons digitally via the project/home site, My page, or through a separate link.",
	"addon:project:residential-account:sublabel": "Give your customers access My page",
	"addon:project:residential-account": "My page (included with active project license)",
	"billing": "Invoice recipient",
	"featuredResidentialPropertyHelper": "Highlight this key value on your project page",
	"viewProject": "View project",
	"unitSelectorIsEntry": "Main unit selector",
	"autocompleteAddressAlert": "Don’t have an address yet? Move the marker on the map to set the position.",
	"externalFileDisclaimer": "The contents of the files have not been checked. You are solely responsible for any external files that are downloaded.",
	"getMarkerPosition": "Get position from map",
	"createResource": "Create {resource}",
	"taskCommentsReceiverInfo": "Messages will be sent to members and to the person who created the task",
	"createdBy": "Created by",
	"openhouseEmptyState": "Get started by creating an open house",
	"documentsEmptyState": "There are no documents linked to this unit",
	"startShops": "Start additional addon shops",
	"noUnitTemplates": "No unit types",
	"unitTemplatesEmptyState": "Get started by creating a unit type",
	"noApplicantShopContractTooltip": "One or more addon shops are not ready to start and will be ignored.",
	"noUsers": "No users",
	"usersEmptyState": "Get started by creating a user",
	"cannotCreateMoreX": "Unable to create more {x}",
	"cannotDelete": "Unable to delete",
	"noX": "No {x}",
	"thisFieldIsFetchedFromX": "This field is retrieved from {x}",
	"addSecondaryColor": "Add @:secondaryColor",
	"primaryColor": "Primary colour",
	"secondaryColor": "Secondary colour",
	"unitMustBeSoldToSetOwner": "The unit must be sold / rented before you can select unit owners",
	"owner": "Owner | Owners",
	"tableFooterTip": "Tips: Press and hold {0} to make multiple simultaneous selections.",
	"sendMessage": "Send message",
	"mediaDescriptionPlaceholder": "Add caption",
	"taskTypeDefault": "@:task",
	"taskTypeComplaint": "@:complaint",
	"downloadX": "Download {x}",
	"noUnsavedChanges": "No unsaved changes",
	"installMore": "Install additional",
	"propertyType:four-person-home": "Fourplex",
	"addCompanyInfo": "Add company info",
	"shopTotalLabel": "Total price for addons",
	"addDesiredAmount": "Enter desired amount below",
	"creationDate": "Creation date",
	"shop-partialConfirmationMessage": "We have received your signed addon order. You will receive a confirmation once the counterparty has signed it. Please find attached an overview of your order.",
	"shop-partialAcceptMessage": "Your addon contract has been accepted",
	"shop-partialDeclinedMessage": "Your addon contract has been rejected",
	"customConfirmationMessage": "We have received your contract and you will receive a reply from us as soon as possible",
	"customAcceptMessage": "Your contract has been accepted",
	"customDeclinedMessage": "You contract has been rejected",
	"noteVisibilityMessage": "Only visible for internal use",
	"flatfinderTypeLeisure": "Cottage selector | Cottage selectors",
	"importContact": "Import contact",
	"externalProductCategory": "Products from external suppliers",
	"shopExternalCategoryLabel": "Other add-ons",
	"shopExternalCategoryDescription": "Products added by external suppliers / business partners",
	"shopExternalProductActionLabel": "Grant access to extern supplier",
	"shopExternalProductActionAlert": "Copy the link below and share with your suppliers / business partners. Click confirm to proceed to the page.",
	"shopExternalProductInfo": "In the form, you can upload new products in the add-on shop to {shop}.",
	"shopExternalProductAddTitle": "Add new add-on.",
	"productName": "Product name",
	"shopExternalDescriptiveCategoryLabel": "Where does the change occur?",
	"shopExternalSupplierSublabel": "Who uploads the product",
	"canEdit": "Can make changes",
	"usersWithAccess": "Users with access",
	"addon:page": "Page",
	"createPage": "Create page",
	"jointDebt": "Housing cooperative joint debt",
	"hasJointDebt": "The unit has housing cooperative joint debt",
	"premiumDesignTemplateTooltip": "Premium design template",
	"about": "About {x}",
	"contactBroker": "Contact broker",
	"energyLabelColor:darkGreen": "",
	"energyLabelColor:lightGreen": "",
	"energyLabelColor:yellow": "",
	"energyLabelColor:orange": "",
	"energyLabelColor:red": "",
	"lift": "",
	"roofTerrace": "",
	"garage": "",
	"janitorservice": "",
	"balcony": "",
	"lookAt": "",
	"rentPageMadeBy": "",
	"submit": "",
	"importStarted": "",
	"available": "",
	"all": "",
	"commercial": "",
	"environmentSustainability": "",
	"clockAt": "at",
	"admin": "",
	"commercialProperty": "",
	"projectDevelopment": "",
	"seeMorePictures": "See more pictures",
	"customPrivacySublabel": "",
	"customPrivacyAlert": "",
	"privacyPolicyTitle": "",
	"projectConsentDialogDescription": "We use cookies to give you the best possible user experience. \nThe cookies are used to analyse our traffic, improve our website, and give content and advertisements a personal touch.\nFor more information, see our {link}.",
	"pageConsentDialogDescription": "We use cookies to give you the best possible user experience. \nThe cookies are used to analyse our traffic, improve our website, and give content and advertisements a personal touch.\nFor more information, see our {link}."
}